import React from 'react'
import { css } from 'emotion'
import { CFImage, CFView, CFLink, PrimaryModal } from 'components'
import { lunch, dinner, takeout } from 'images'

export default props => {
  return (
    <PrimaryModal
      title={'View Sanjo Sushi Menu'}
      isOpen={props.showModal}
      onRequestClose={props.onRequestClose}
    >
      <CFView className={styles.container} bg="#FDFCED">
        <CFView column alignCenter w="100%" mv="10px">
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
            mb="20px"
          >
            <CFLink href={dinner} target="_blank">
              <CFView h4 black bold>
                Dine-In Menu
              </CFView>
            </CFLink>
          </CFView>
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
            mb="20px"
          >
            <CFLink href={lunch} target="_blank">
              <CFView h4 black bold>
                Dine-In Lunch Menu
              </CFView>
            </CFLink>
          </CFView>
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
          >
            <CFLink href={takeout} target="_blank">
              <CFView h4 black bold>
                Take-Out Menu
              </CFView>
            </CFLink>
          </CFView>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    '@media (max-width: 767px)': {
      padding: '30px',
      width: '330px',
    },
  }),
}
