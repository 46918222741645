import React from 'react'
import { css } from 'emotion'
import { CFImage, CFView, PrimaryModal } from 'components'
import { doorDash, skipTheDishes, uberEats } from 'images'

export default props => {
  return (
    <PrimaryModal
      title={'Choose Delivery Provider'}
      isOpen={props.showModal}
      onRequestClose={props.onRequestClose}
    >
      <CFView className={styles.container}>
        <CFView column alignCenter w="100%">
          <a
            href={`https://www.doordash.com/en-CA/store/sanjo-sushi-new-westminster-24913911/?cursor=eyJzdG9yZV92ZXJ0aWNhbF9pZCI6bnVsbCwic2VhcmNoX2l0ZW1fY2Fyb3VzZWxfY3Vyc29yIjp7InF1ZXJ5Ijoic2Fuam8gc3VzaGkiLCJpdGVtX2lkcyI6W10sInNlYXJjaF90ZXJtIjoic2Fuam8gc3VzaGkiLCJ2ZXJ0aWNhbF9pZCI6LTk5LCJ2ZXJ0aWNhbF9uYW1lIjoiIn0sInN0b3JlX3ByaW1hcnlfdmVydGljYWxfaWRzIjpbMV0sImlzX3NpYmxpbmciOmZhbHNlLCJmb3JjZV9zdG9yZV9hdmFpbGFiaWxpdHlfdjIiOmZhbHNlfQ==&pickup=false`}
          >
            <CFView hover>
              <CFImage maxWidth="290px" src={doorDash} alt="Door Dash" />
            </CFView>
          </a>
          <a href={`https://www.skipthedishes.com/sanjo-sushi-546`}>
            <CFView hover mt="15px">
              <CFImage
                maxWidth="290px"
                src={skipTheDishes}
                alt="Skip the Dishes "
              />
            </CFView>
          </a>
          <a
            href={`https://www.ubereats.com/store/sanjo-sushi/INwYIli_VJG9nX-Nz95HeA?diningMode=DELIVERY`}
          >
            <CFView hover mt="15px">
              <CFImage maxWidth="290px" src={uberEats} alt="UberEats" />
            </CFView>
          </a>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    '@media (max-width: 767px)': {
      padding: '30px',
      width: '330px',
    },
  }),
}
