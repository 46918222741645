import React, { useState } from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import DeliveryModal from './DeliveryModal'
import {
  hero,
  heroText,
  mobileHero,
  mobileHeroText,
  deliveryButton,
} from 'images'

export default () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          justifyStart
        >
          <Header />
          <CFView textCenter column center w="100%" h="90vh">
            <CFView column center>
              <CFImage src={mobileHeroText} w="85%" alt="Promotions" />
            </CFView>
            <CFView mt="30px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView hover onClick={() => setShowModal(true)} column center>
              <CFImage
                mv="10px"
                w="100%"
                maxWidth="160px"
                src={deliveryButton}
                alt="Order delivery"
              />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) top/ cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
        >
          <Header />
          <CFView w="100%" column center h="650px">
            <CFView column center mt="15px">
              <CFImage src={heroText} w="50%" alt="Promotions" />
            </CFView>
            <CFView mt="30px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView hover onClick={() => setShowModal(true)} column center>
              <CFImage
                mv="10px"
                w="100%"
                maxWidth="160px"
                src={deliveryButton}
                alt="Order delivery"
              />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <DeliveryModal
        showModal={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </CFView>
  )
}
